<template>
  <!-- Fix lost fle -->
  <div>
    <b-card title="RATE">
      <!-- Form -->

      <b-form-input
        v-model="message"
        class="d-inline-block mr-1"
        :placeholder="$t('Search')"
        @input="sendTypingWS($event)"
      />
      <b-col md="6">
        <b-form-group>
          <flat-pickr
            v-model="date"
            class="form-control"
            :config="{
              altInput: true,
              altFormat: 'F j, Y',
              dateFormat: 'Y-m-d',
            }"
          />
        </b-form-group>
      </b-col>
      <!-- Action button -->
      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="submit()"
        >
          Submit
        </b-button>
      </div>

      <div class="d-flex mt-2">
        <b-button
          variant="primary"
          class="mr-2"
          @click="review()"
        >
          review
        </b-button>
      </div>
    </b-card>
  </div>
</template>

<script>
import store from '@/store'

import {
  BCard, BFormInput, BFormGroup, BCol, BButton,
} from 'bootstrap-vue'
import debounce from 'debounce'
import flatPickr from 'vue-flatpickr-component'
import contactStoreModule from '@/store/root/contactUs'

const STORE_MODULE_NAME = 'contactUs'

export default {
  components: {
    BCard,
    // BForm,
    BFormInput,
    flatPickr,
    BFormGroup,
    // ValidationProvider,
    // BRow,
    BCol,
    BButton,
    // BCardText,
  },
  data() {
    return {
      dataId: null,
      message: '',
      date: Date.now(),
    }
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respDataChat
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
  },
  created() {
    const Id = this.$route.params.id
    console.log(this.$route)
    this.dataId = Id
    // WebSocket Connect
    const pathname = `/Contact?type=root&uid=${Id}`
    const params = {
      pathname,
      page: STORE_MODULE_NAME,
    }
    store.dispatch('websockets/connect', params)

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, contactStoreModule)
    console.log(this.$store)
    store
      .dispatch(`${STORE_MODULE_NAME}/chat`, this.dataId)
      .then(result => {
        console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
      })
      .catch(error => {
        this.show = false
        console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
      })
    this.sendTypingWS = debounce(this.sendTypingWS, 500)
  },
  destroyed() {
    // WebSocket Disconnect
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)

    // Unregister module
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  beforeMount() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)
        console.log('SocketMessage')
        console.log(SocketMessage)
      }
    }
  },
  methods: {
    review() {
      const Id = this.$route.params.id
      const obj = {
        contactUsId: Id,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/setInReview`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    submit() {
      const Id = this.$route.params.id
      const obj = {
        contactUsId: Id,
        message: this.message,
        type: 0,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/sendMessage`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
        // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    sendTypingWS(value) {
      console.log(value)
      if (value.trim() !== '') {
        const dataSend = {
          data: null, // ข้อมูลที่ส่ง
          page: 'CONTACT_US', // ชื่อเมนู
        }
        const webSocketData = {
          send: dataSend, // ข้อมูลที่จะส่ง ห้ามเป็น Array [Map]
          // ส่งไปหา users(ผู้ใช้ทั้งหมดที่ออนไลน)
          // users(ผู้ใช้งานทั้งหมด)
          // root(ผู้ดูแลระบบทั้งหมด)
          // agents(ตัวแทนทั้งหมด)
          // members(คนขับทั้งหมด)
          // driver(คนขับทั้งหมด)
          // web(เว็บทั้งหมด)
          // mobile(มือถือทั้งหมด)
          // ถ้าจะส่งหา USER แยก จะใช้ USER_{ObjectID ของผู้ใช้ที่จะส่ง} [refer_users] [USER_61554f40a0f9d6f42808655e]
          // ถ้าจะส่งหา Call a car แยก จะใช้ CALL_{ObjectID ของการเรียกรถ} [refer_call_a_car] [CALL_61554f40a0f9d6f42808655e]
          // ถ้าจะส่งหา Contact Us แยก จะใช้ CONTACT_{ ObjectID ของการสนทนา } [refer_contact_us] [CONTACT_61554f40a0f9d6f42808655e]
          author: [`CONTACT_${this.dataId}`], // Example author: ['root','agents','USER_61554f40a0f9d6f42808655e'],
          method: 'VIEW', // ['ADD','DELETE','UPDATE','VIEW']
          sendMethod: 'WAIT_DATA', // WAIT_DATA รอข้อมูล เช่น กำลังตอบกลับ แต่ไม่ได้ส่งอะไรมาใน send['data'] ด้วย , SEND_DATA ส่งข้อมูล เช่น เก็บข้อมูล ส่งข้อมูลมาใน send['data'] ด้วย

          // ผู้ส่งไป
          type: 'root', //  Web :['root','agents'] , Moblie :['member','driver'] *เอาไว้กันลืม [String]
          pathname: 'Web', // ['Web','Mobile'] *เอาไว้กันลืม [String]
        }
        const obj = {
          data: webSocketData,
          page: STORE_MODULE_NAME,
        }
        store.dispatch('websockets/sendMessage', obj)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
