import axios from '@axios'

export default {
  namespaced: true,
  state: {
    respDataContactUs: [],
    respDataChat: [],
  },
  getters: {
  },
  mutations: {
    GET_CONTACT_US(state, payload) {
      state.respDataContactUs = payload
    },
    GET_CONTACT_US_CHAT(state, payload) {
      state.respDataChat = payload
    },
  },
  actions: {
    getContactUs({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/contactUs/get?pagesize=${item.pageSize}&currentPage=${item.currentPage}`)
          .then(response => {
            console.log(response.data)
            commit('GET_CONTACT_US', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    chat({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/web/root/contactUs/getChat?id=${item}`)
          .then(response => {
            console.log(response.data)
            commit('GET_CONTACT_US_CHAT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    sendMessage({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.post('/api/web/root/contactUs/sendMessage', item)
          .then(response => {
            console.log(response.data)
            // commit('GET_CONTACT_US_CHAT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // eslint-disable-next-line no-unused-vars
    setInReview({ commit }, item) {
      return new Promise((resolve, reject) => {
        axios.put('/api/web/root/contactUs/setInReview', item)
          .then(response => {
            console.log(response.data)
            // commit('GET_CONTACT_US_CHAT', response.data)
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}
